<template>
  <div class="main">
    <van-tree-select
        v-model:active-id="state.activeId"
        v-model:main-active-index="state.activeIndex"
        :items="items"
        @click-item="itemClick"
        selected-icon=""
        height="100%"
    />
  </div>

</template>

<script>

import {
    ref,
    reactive,
    onMounted
} from 'vue';
import { useRouter } from 'vue-router';
import { TreeSelect } from 'vant';
import Baike from "../../models/Baike";


export default {
  setup() {
    let items = ref([]);

    onMounted(() => {
      Baike.jqBaike().then(res => {
        let data = [];
        Array.from(res.data).forEach(item => {
          let child = [];
          Array.from(item.doc).forEach(docItem => {
            child.push({
              'id': docItem.id,
              'text': docItem.title
            })
          })
          data.push({
            'text': item.title,
            'children': child
          })
        })
        items.value = data;
        //     [
        //   {
        //     text: '浙江',
        //     children: [
        //       { text: '杭州', id: 1 },
        //       { text: '温州', id: 2 },
        //     ],
        //   },
        //   {
        //     text: '江苏',
        //     children: [
        //       { text: '南京', id: 5 },
        //       { text: '无锡', id: 6 },
        //     ],
        //   },
        // ];

      });
    });

    const state = reactive({
      activeId: 1,
      activeIndex: 0,
    });
    const router = useRouter()
    return {
      state,
      items,
      router
    };
  },
  methods: {
    itemClick(data) {
      this.router.push('/baike/docDetail?id='+data.id)
    }
  },
  components: {
    [TreeSelect.name]: TreeSelect
  }
};
</script>
<style scoped>
.main {
  margin-top: 0;
}
body {
  background-color: #fff!important;
}
</style>